import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout/layout.js"
// import Image from "../components/image";
// import SEO from "../components/seo";
import BulletPoint from "../../components/shared/bullet-point.js"
import SkillPoint from "../../components/shared/skill-point"
import ModalImage from "react-modal-image"

const webDesignData = [
  "3distudio.png",
  "Roadslink.png",
  "acekids.png",
  "almarjantours.png",
  "chateau-frames.png",
  "chichi.png",
  "churros.png",
  "daralnuzm.png",
  "designershome.jpg",
  "elite4g.png",
  "everred.png",
  "jollibee.png",
  "kwsme.jpg",
  "loveboat.png",
  "marshal.png",
  "msstechnical.png",
  "muskme.png",
  "nailcorner.png",
  "physiocenterdubai.png",
  "pinkcloud.png",
  "risingstar.png",
  "sheikhmanaa.jpg",
  "stakeholder.jpg",
  "teksignal.jpg",
  "xhairlounge.jpg",
]

const WebDesigns = () => (
  <Layout>
    {/* <SEO title="Home" /> */}

    <div className="pb-0 pb-sm-2">
      <h1 className="title title--h1 text-center">Web Designs</h1>

      {/*<p>*/}
      {/*  KLU was founded by two friends who kept getting approached with the same*/}
      {/*  questions "What should I get him for his birthday?, "What should I get*/}
      {/*  to this dinner I'm invited to?", "What should I get her for Valentine's*/}
      {/*  Day?" And "Which one would they like more?". Every year, like clock*/}
      {/*  work, at every occasion, their friends would rely on them for the best*/}
      {/*  recommendation. So one day, realizing it's a common question (struggle*/}
      {/*  that most of us have) they decided to do something about it, and help*/}
      {/*  others find the perfect gift. So they embarked on a journey to find the*/}
      {/*  best way to match people to the best gift possible!*/}
      {/*</p>*/}
      {/*<p>I was part of this project to design the PWA.</p>*/}

      {/*<h4>High level goals</h4>*/}
      {/*<ol>*/}
      {/*  <li>Let People Build their Profile.</li>*/}
      {/*  <li>Make games to get Users likes/dislikes.</li>*/}
      {/*  <li>Let Users invite their Friends & family.</li>*/}
      {/*</ol>*/}
    </div>

    {/*// <!-- Experience -->*/}

    <div className="pb-0">
      <div className="row">
        <div className="col-12 col-lg-12">


          <div
            className="row"
            // style={{ paddingBottom: "10px", backgroundColor: "#eee", padding: "20px 0px" }}
          >
            {webDesignData.map((x, i) => (
              <div className={"col-md-6"} key={i}>
                <div
                  className="portfolio-images-parent"
                  style={{
                    backgroundImage: `url(../assets/img/web-designs/${webDesignData[i]}`,
                  }}
                >
                  {/*<img src={"../assets/img/template.png"} alt="" />*/}

                  <div className="portfolioimages">
                    <ModalImage
                      small={"../assets/img/template.png"}
                      large={`../assets/img/web-designs/${webDesignData[i]}`}
                      alt={`${webDesignData[i].split(".")[0].toUpperCase()}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/*<figure className="gallery-grid__item category-design">*/}
          {/*  <div className="gallery-grid__image-wrap">*/}
          {/*    <img className="gallery-grid__image cover lazyload" src="../assets/img/image_05.jpg" data-zoom alt=""/>*/}
          {/*  </div>*/}
          {/*  <figcaption className="gallery-grid__caption">*/}
          {/*    <h4 className="title gallery-grid__title">Abstract #3</h4>*/}
          {/*    <span className="gallery-grid__category">Design</span>*/}
          {/*  </figcaption>*/}
          {/*</figure>*/}

          <div
            className="gallery-grid__image-wrap col-md-12"
            style={{ paddingBottom: "10px" }}
          >
            {/*<img*/}
            {/*  className="gallery-grid__image cover lazyload"*/}
            {/*  src={"../assets/img/image_01.jpg"}*/}
            {/*  alt=""*/}
            {/*/>*/}
          </div>

          <div className="timeline">
            {/*// <!-- Item -->*/}

            {/*<BulletPoint*/}
            {/*  title={"UX/UI & Front-end Developer"}*/}
            {/*  subTitle={"Group Rhinos"}*/}
            {/*  year={"(2018 — Present)"}*/}
            {/*  description={*/}
            {/*    "Working as UX/UI Designer. Hold stakeholder meetings and discuss sketched/inspiration ideas. Do user research and create User epics and user stories. Create User flows and wireframes. Create Prototypes and have it tested with client and stakeholders. Create UI components in Flutter(Dart) or ReactJS."*/}
            {/*  }*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </div>

    {/* <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default WebDesigns
